﻿.mat-form-field {
  &.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: theme-color("info");
    }
  }
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: theme-color("info");
}

.mat-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: theme-color("info");
  }
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) {
  .mat-form-field-infix::after {
    color: theme-color("info");
  }
}

.mat-datepicker-toggle {
  .mat-icon-button {
    color: theme-color("info");
  }
}