﻿.soco-logo {
  background-image: url("/assets/images/southernco_h_rgb.png");
}

.app-navbar {
  .mat-button {
    padding-left: 1rem;
    padding-right: 1rem;
    color: $body-color;
    font-size: $font-size-base;

    &:hover,
    &:active {
      background-color: transparent;
      color: $body-color;

      .mat-button-focus-overlay {
        opacity: .04;
      }
    }

    &.active {
      color: $link-color;
    }
  }

  .mat-button-wrapper {
    text-transform: none;
  }

  .app-navbar-brand {
    color: $body-color;
  }
}