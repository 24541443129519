﻿.alert {
  &.soco-alert {
    &.soco-alert-system {
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 1020;
    }

    .soco-alert-icon {
      display: table-cell;
      font-size: 1.5em;
      vertical-align: middle;

      @media (max-width:375px) {
        font-size: 1.35em;
      }
    }

    .message {
      display: table-cell;
      padding: 0 0 0 20px;
      vertical-align: middle;
    }
  }
}
