.body-content {
  padding-top: 15px;
  padding-bottom: 15px;

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.container {
  width: 400px;
}

.separator {
  color: $gray-300;
  margin-left: map-get($spacers, 2);
  margin-right: map-get($spacers, 2);
}

.page-title {
  margin-bottom: map-get($spacers, 5);
}

a:not([href]) {
  color: $link-color;
  text-decoration: $link-decoration;
  cursor: pointer;
  
  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}