.password-complexity-requirements {
    li {
        padding-left: 1em;
    }

    li.cpr-passed {
        color: $success;

        &::marker {
            content: '✓';
        }
    }

    li.cpr-violated {
        color: $danger;

        &::marker {
            content: '🞪';
        }
    }

    li.cpr-unchecked {
        &::marker {
            content: '•';
        }
    }
}