﻿.alert {
  border-left-width: 4px;
  box-shadow: 0px 2px 10px #ccc; //$box-shadow
  color: $body-color;

  &.soco-alert {
    .message {
      padding-top: $alert-padding-x;
      padding-bottom: $alert-padding-x;
    }
  }
}

@each $color, $value in $theme-colors {
  .soco-alert.alert-#{$color} {
    .soco-alert-icon {
      color: theme-color-level($color, $alert-color-level);
    }
  }
}