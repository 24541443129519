﻿.table {
  font-size: $font-size-sm;

  tr {
    min-height: 3rem;
  }

  thead th {
    font-weight: $font-weight-normal;
    border-bottom-width: 1px;
  }

  th,
  td {
    vertical-align: inherit;
  }
}
