﻿.soco-logo {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  height: 21px;
  width: 21px;
  margin: 4px 5px 0 0;
}

.app-navbar {
  .mat-button {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  .soco-logo {
    display: none;

    @include media-breakpoint-up(lg) {
      background-size: contain;
      display: block;
      float: right;
      height: 21px;
      width: 146px;
      margin: 0 15px 0 0;
    }
  }

  .app-navbar-brand {
    margin-right: 2rem;

    .soco-logo {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}

.top-row {
  height: 35px;
  line-height: 35px;
  padding: 0 40px;
  border-bottom: 1px solid #e9e9e9;
  color: theme-color("secondary");
  background-color: $body-bg;
  text-align: right;

  @include media-breakpoint-down(md) {
    display: none;
  }

  a {
    color: theme-color("secondary");
  }
}

.webauth-user,
.webauth-logout {
  font-size: 0.8em;
  text-transform: uppercase;
  color: theme-color("secondary");
  white-space: nowrap;

  .pipe {
    padding: 0 10px;
  }

  a {
    color: theme-color("secondary");

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}
