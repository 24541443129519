﻿// sidenav
.app-sidenav {
  width: 260px;

  .menu-button {
    transition: 300ms ease-in-out;
    transform: rotate(0deg);
  }

  .menu-button.rotated {
    transform: rotate(180deg);
  }

  .submenu {
    overflow-y: hidden;
    transition: all 300ms ease;
    transform: scaleY(0);
    transform-origin: top;
    padding-left: 30px;
    height: 0;
  }

  .submenu.expanded {
    transform: scaleY(1);
    height: 100%;
  }

  .logo {
    position: relative;
    padding: 10px 1rem;

    .logo-text {
      display: block;
      color: $body-color;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 30px;
      padding: 10px 0;
      height: 50px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
  }

  .user {
    position: relative;
    padding: 0 1rem 20px;
    margin: 20px auto 0;
  }

  .logo::after,
  .user::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 1rem;
    height: 1px;
    width: calc(100% - 2rem);
    background-color: rgba(0,0,0,.1);
  }

  .webauth-user,
  .webauth-logout {
    overflow-x: hidden;
    text-overflow: ellipsis;

    a {
      padding: 0;
    }
  }
}
