﻿.mat-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button {
  border-radius: 0;
  padding-left: 40px;
  padding-right: 40px;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-mini-fab,
.mat-raised-button {
  &:not([disabled]) {
    &:hover,
    &:active {
      background-color: theme-color("info");
      color: $white;
      transition: background-color .2s $ease-in-out-curve-function, color .2s $ease-in-out-curve-function;
    }

    &:focus {
      outline-color: theme-color("info");
    }

    &.mat-primary {
      &:hover, &:active {
        background-color: darken(theme-color("danger"), 10%);
        color: $white;
      }

      &:focus {
        outline-color: $white;
      }
    }

    &.mat-accent {
      &:hover,
      &:active {
        background-color: darken(theme-color("info"), 10%);
        color: $white;
      }

      &:focus {
        outline-color: $white;
      }
    }

    &.mat-warn {
      &:hover,
      &:active {
        background-color: darken(theme-color("danger"), 10%);
        color: $white;
      }

      &:focus {
        outline-color: $white;
      }
    }
  }
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: $link-color;

  &:hover {
    .mat-button-focus-overlay {
      opacity: 0;
    }
  }

  &.mat-primary {
    color: theme-color("danger");
  }
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: $link-color;

  &:hover {
    .mat-button-focus-overlay {
      opacity: 0;
    }
  }

  &.mat-primary {
    background-color: theme-color("danger");
  }
}
