// Bootstrap
@import "base/bootstrap/reboot";
@import "base/bootstrap/card";
@import "base/bootstrap/table";
@import "base/bootstrap/utilities/text";

// Material
@import "base/material";

@import "base/variables";
@import "base/alerts";
@import "base/buttons";
@import "base/core";
@import "base/forms";
@import "base/modals";
@import "base/navbar";
@import "base/sidebar";
/*@import "base/soco-employee";*/
@import "base/typography";
