.mat-error + .mat-error {
  display: none;
}

mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }

  &-subscript-wrapper {
    position: static;
  }
}

.mat-form-field {
  input[type=password] {
    letter-spacing: .25em;
    font-weight: bold;
  }
}

.mat-radio-button {
  display: block;
}

.soco-form-footer {
  .mat-button-base {
    width: 100%;

    + .mat-button-base {
      margin-left: 0;
      margin-top: map-get($spacers, 2);
    }
  }
}

soco-form-section {
  &.mb-1 {
    .soco-form-section {
      margin-bottom: map-get($spacers, 1);
    }
  }
  &.mb-2 {
    .soco-form-section {
      margin-bottom: map-get($spacers, 2);
    }
  }
  &.mb-3 {
    .soco-form-section {
      margin-bottom: map-get($spacers, 3);
    }
  }
  &.mb-4 {
    .soco-form-section {
      margin-bottom: map-get($spacers, 4);
    }
  }
  &.mb-5 {
    .soco-form-section {
      margin-bottom: map-get($spacers, 5);
    }
  }
}