﻿.mat-table {
  width: 100%;

  @extend .table-striped;
}

.table-striped {
  th.mat-header-cell, td.mat-cell {
    border-bottom-color: transparent;
  }
}