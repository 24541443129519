﻿html, body {
  height: 100%;
}

.wrapper {
  height: 100%;
}

.body-content {
  padding-top: 40px;
  padding-bottom: 20px;
}

.soco-system-message {
  padding-top: 20px;
  text-align: center;
  margin: auto;
}

soco-system-message[messageType="Loading"] {
  padding-top: 200px;
  text-align: center;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgba(238,238,238,0.6);
  color: #777;

  i {
    color: lighten($orange, 10%);
  }
}

// SoCo HTTP - Please Wait
.please-wait {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.5);
  z-index: 99999;
  opacity: 1;

  .please-wait-spinner {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
  }
}
