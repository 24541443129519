#logo {
  margin: 0 auto map-get($spacers, 4);
  border-bottom: 1px solid $hr-border-color;

  @include media-breakpoint-down(sm) {
    padding-bottom: map-get($spacers, 2);
  }
}

.wa-logo {
  height: 100px;
  width: 100%;
  max-width: 280px;
  background: url(../../images/southernco_h_rgb.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    height: 60px;
    width: 180px;
    margin: 0;
  }
}
