.customer-service-container {
  margin: map-get($spacers, 3) 0;

  mat-icon {
    line-height: 1.5;
  }

  .mat-expansion-panel-header {
    justify-content: center;

    .mat-content {
      flex: 0 1 auto;
    }
  }
}

.customer-service {
    font-size: .75rem;
    text-align: center;

    .company-icon {
        width: .8rem;
        height: .8rem;
        margin-right: .1875rem;
        vertical-align: baseline;
    }

    h4 {
        color: map-get($colors, "gray");
        font-size: .8rem;
        font-weight: 500;
        margin-bottom: map-get($spacers, 2);
    }

    li+li {
        border-top: 1px solid #e8e8e8;
        margin-top: map-get($spacers, 2);
        padding-top: map-get($spacers, 2);
    }
}