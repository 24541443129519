/**
    This file is the endpoint for all partials used by this theme.
    In order to allow easier upgrades in the future, we encourage overrides instead of
    modifying the original source .scss files.

    Libraries included:
    Bootstrap 4 - https://getbootstrap.com/
    Angular Material - https://material.angular.io/
    Kendo UI Material Theme - https://www.telerik.com/kendo-angular-ui/components/styling/theme-material/
*/

@import "app/variables";
@import "~bootstrap/scss/bootstrap";

@import "~@angular/material/theming";

// Kendo UI - *NOTE: uncomment if using Kendo in your application
/*@import "kendo";*/

// Custom theme changes
.external {
  @import "app/base";
  /*@import "app/default";*/
  @import "app/external";
  /*Site specific changes*/
  @import "site";
}