.mat-step-header {
  border-radius: $border-radius-lg;
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 1rem;
}

.mat-horizontal-stepper-header {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;

  .mat-step-icon,
  .mat-step-icon-not-touched {
    margin-right: 0 !important;
    margin-bottom: 8px;
  }

  .mat-step-label {
    line-height: 1;
    white-space: normal;
  }
}
