﻿/* buttons */
.mat-button-wrapper {
  text-transform: uppercase;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  .mat-button-wrapper > .mat-icon {
    vertical-align: baseline !important;
  }
}

.mat-button + .mat-raised-button,
.mat-button + .mat-stroked-button,
.mat-button + .mat-flat-button,
.mat-button + .mat-button,
.mat-raised-button + .mat-button,
.mat-raised-button + .mat-stroked-button,
.mat-raised-button + .mat-flat-button,
.mat-raised-button + .mat-raised-button,
.mat-stroked-button + .mat-button,
.mat-stroked-button + .mat-flat-button,
.mat-stroked-button + .mat-raised-button,
.mat-stroked-button + .mat-stroked-button,
.mat-flat-button + .mat-button,
.mat-flat-button + .mat-raised-button,
.mat-flat-button + .mat-stroked-button,
.mat-flat-button + .mat-stroked-button {
  margin-left: calc($spacer / 2);
}
