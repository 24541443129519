﻿.mat-form-field {
  width: 100%;
}

.mat-checkbox-inner-container {
  height: 24px;
  width: 24px;
}

.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  height: 48px;
  width: 48px;
}

.mat-radio-container {
  width: 24px;
  height: 24px;
}

.mat-radio-outer-circle {
  height: 24px;
  width: 24px;
}

.mat-radio-inner-circle {
  height: 24px;
  width: 24px;
}

.mat-radio-button .mat-radio-ripple {
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  height: 48px;
  width: 48px;
}